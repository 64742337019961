"use client";
import type { ImageProps } from "next/image";
import Image from "next/image";
import { imageUrlFor } from "./imageBuilder";
import { ImageAssetQueryResult } from "../sanity.types";

export type SanityAssetImageProps = {
  asset?: ImageAssetQueryResult;
  aspectRatio?: "16/9" | "1/1" | "4/3" | number;
} & Omit<ImageProps, "src" | "alt" | "height" | "width"> &
  Pick<Partial<ImageProps>, "alt" | "height" | "width">;

export function SanityAssetImage({
  asset,
  aspectRatio,
  width,
  height,
  fill,
  ...rest
}: SanityAssetImageProps) {
  if (!asset) return null;

  const imageBuilder = imageUrlFor(asset);

  const ratio =
    typeof aspectRatio === "number"
      ? aspectRatio
      : (aspectRatio
          ?.split("/")
          .reduce(
            (acc, cur) => (acc ? acc / parseInt(cur) : parseInt(cur)),
            0,
          ) ??
        asset.metadata?.dimensions?.aspectRatio ??
        1);

  const dimensions = fill
    ? { height: undefined, width: undefined, fill: true }
    : {
        height:
          height ??
          Math.round(
            Number(width ?? asset.metadata?.dimensions?.width ?? 1) / ratio,
          ),
        width:
          width ??
          Math.round(
            Number(height ?? asset.metadata?.dimensions?.height ?? 1) * ratio,
          ),
        fill: false,
      };

  if (asset.extension === "svg") {
    return (
      <Image
        blurDataURL={asset.metadata?.lqip}
        placeholder="blur"
        src={imageBuilder.url()}
        alt={asset.altText}
        {...dimensions}
        {...rest}
      />
    );
  }

  return (
    <Image
      loader={({ width: w, quality }) =>
        imageBuilder
          .withOptions({
            format: "webp",
            quality,
            width: w,
            height: ratio ? Math.round(w / ratio) : undefined,
          })
          .url()
      }
      src={imageBuilder.format("webp").url()}
      alt={asset.altText ?? "Misssing alt text"}
      placeholder="blur"
      blurDataURL={asset.metadata?.lqip}
      {...dimensions}
      {...rest}
    />
  );
}
